import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useRepliesSortOrderProperties from '@aurora/shared-client/components/nodes/NodeProperties/useRepliesSortOrderProperties';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import {
  RepliesSortOrder,
  UserRepliesSortOrder
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import { useContext } from 'react';
import type {
  UserRepliesSettingsQuery,
  UserRepliesSettingsQueryVariables
} from '../../types/graphql-types';
import { MessageListMenuItem } from './MessageListMenu/MessageListMenu';
import userRepliesSettings from './MessageListMenu/UserRepliesSettings.query.graphql';

interface MessageSortMenuItem {
  /**
   * The loading state.
   */
  loading: boolean;
  /**
   * The user preferred setting for sort order.
   */
  sortMenuItem: MessageListMenuItem;
}

/**
 *
 * @param queryKey
 */
export default function useMessageSort(
  queryKey: EndUserQueryParams = EndUserQueryParams.SORT_MENU_QUERY_PARAMETER
): MessageSortMenuItem {
  const { router } = useEndUserRoutes();

  const { contextNode } = useContext(AppContext);
  const { isAnonymous } = useRegistrationStatus();
  const { data: userData, loading: userLoading } = useQueryWithTracing<
    UserRepliesSettingsQuery,
    UserRepliesSettingsQueryVariables
  >(module, userRepliesSettings, { skip: isAnonymous });

  const repliesSortOrder =
    userData?.self?.profileSettings?.repliesSortOrder?.value ?? UserRepliesSortOrder.Default;

  const { loading: repliesSortOrderLoading, data: repliesSortOrderProperties } =
    useRepliesSortOrderProperties(
      module,
      { nodeId: contextNode.id },
      { skip: repliesSortOrder !== UserRepliesSortOrder.Default }
    );

  if (userLoading || repliesSortOrderLoading) {
    return {
      loading: true,
      // Returning the default sort order as Likes if the query is loading or fails for some reason
      sortMenuItem: getAsEnum<typeof MessageListMenuItem>(
        MessageListMenuItem.KUDOS_SUM_WEIGHT_DESC,
        MessageListMenuItem
      )
    };
  }

  const adminSortOrder = repliesSortOrderProperties?.coreNode?.repliesProperties?.sortOrder;

  const selectedSortOrder =
    repliesSortOrder === UserRepliesSortOrder.Default ? adminSortOrder : repliesSortOrder;

  const sortOrderMapping = {
    [RepliesSortOrder.Likes]: MessageListMenuItem.KUDOS_SUM_WEIGHT_DESC,
    [RepliesSortOrder.PublishTime]: MessageListMenuItem.POST_TIME_ASC,
    [RepliesSortOrder.ReversePublishTime]: MessageListMenuItem.POST_TIME_DESC
  };

  const defaultSort = isAnonymous
    ? sortOrderMapping[adminSortOrder]
    : selectedSortOrder === UserRepliesSortOrder.Likes
    ? MessageListMenuItem.KUDOS_SUM_WEIGHT_DESC
    : selectedSortOrder === UserRepliesSortOrder.PublishTime
    ? MessageListMenuItem.POST_TIME_ASC
    : MessageListMenuItem.POST_TIME_DESC;

  const sortMenuItemString = router.getUnwrappedQueryParam(queryKey, defaultSort);
  return {
    loading: false,
    sortMenuItem: getAsEnum<typeof MessageListMenuItem>(
      sortMenuItemString,
      MessageListMenuItem,
      defaultSort
    )
  };
}
