import type { QueryResult } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client/react/types/types';
import type {
  NodeRepliesPropertiesQuery,
  NodeRepliesPropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import AppContext from '../../context/AppContext/AppContext';
import useQueryWithTracing from '../../useQueryWithTracing';
import repliesProperties from './NodeRepliesProperties.query.graphql';

const log = getLog(module);

/**
 * Gets node-specific properties for replies sort order property.
 *
 * @param module The module
 * @param variables any node property query variables.
 * @param options optional query options
 * @param useCache whether to use the request scoped cached value if one exists.
 *
 */
export default function useRepliesSortOrderProperties(
  module: NodeModule | string,
  variables: Required<NodeRepliesPropertiesQueryVariables>,
  options?: QueryHookOptions<NodeRepliesPropertiesQuery, NodeRepliesPropertiesQueryVariables>,
  useCache = true
): QueryResult<NodeRepliesPropertiesQuery, NodeRepliesPropertiesQueryVariables> {
  const { contextNode } = useContext(AppContext);
  const modifiedVariables = { id: contextNode.id, ...variables };
  const result = useQueryWithTracing<
    NodeRepliesPropertiesQuery,
    NodeRepliesPropertiesQueryVariables
  >(module, repliesProperties, {
    ...(!useCache && { fetchPolicy: 'network-only' }),
    ...options,
    variables: modifiedVariables
  });
  if (result?.error) {
    log.error(
      result.error,
      'Error getting node replies sort order properties for %O',
      modifiedVariables
    );
  }
  return result;
}
