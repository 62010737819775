import type { Editor } from 'tinymce';
import { Actions } from './TinyMceInternalHelper';
import { DATA_CODE_VALUE } from './EditorCodeSampleHelper';

// Copied from src/plugins/codesample/main/ts/util/Utils.ts
function isCodeSample(elm: Element) {
  return elm && elm.nodeName === 'PRE' && elm.className.includes('language-');
}

// Copied from src/plugins/codesample/main/ts/util/Utils.ts
function trimArgument(predicateFunction: (a) => boolean) {
  return (argument1, argument2) => {
    return predicateFunction(argument2);
  };
}

// Copied from src/plugins/codesample/main/ts/core/Languages.ts
function getCurrentLanguage(node: Element): string {
  if (!node) {
    return 'markup';
  } else {
    const matches = node.className.match(/language-(\w+)/);
    return matches ? matches[1] : 'markup';
  }
}

// Copied from src/plugins/codesample/main/ts/ui/Buttons.ts
function isCodeSampleSelection(editor: Editor) {
  const node = editor.selection.getStart();
  return editor.dom.is(node, 'pre[class*="language-"]');
}

// Copied from src/plugins/codesample/main/ts/core/FilterContent.ts
function handleSetContent(editor: Editor) {
  const { dom } = editor;
  const allPreTags = dom.select('pre');

  const filteredPreTags = [];
  allPreTags.forEach(currentPreTag => {
    const isPreTagCodeSample = isCodeSample(currentPreTag);
    const contentEditable = dom.getAttrib(currentPreTag, 'contentEditable') !== 'false';
    if (isPreTagCodeSample && contentEditable) {
      filteredPreTags.push(currentPreTag);
    }
  });

  const unprocessedCodeSamples = filteredPreTags;

  if (unprocessedCodeSamples.length > 0) {
    editor.undoManager.transact(() => {
      unprocessedCodeSamples.forEach(elm => {
        const parentElement = elm;
        const brElements = dom.select('br', parentElement);
        [...brElements].forEach(brElement => {
          const newNode = editor.getDoc().createTextNode('\n');
          dom.replace(newNode, brElement);
        });

        const code = elm.textContent ?? '';
        dom.setAttrib(elm, 'contentEditable', 'false');
        dom.setAttrib(elm, DATA_CODE_VALUE, code);
        elm.textContent = code;
        elm.className = elm.className.trim();
      });
    });
  }
}

// Copied from src/plugins/codesample/main/ts/core/FilterContent.ts
function handlePreProcess(editor: Editor) {
  const { dom } = editor;
  const preElements = dom.select('pre[contenteditable=false]');

  for (const elm of preElements) {
    if (isCodeSample(elm)) {
      const code = elm.textContent;

      dom.setAttrib(elm, 'class', dom.getAttrib(elm, 'class').trim());
      dom.setAttrib(elm, DATA_CODE_VALUE, code);
      elm.textContent = '';

      if (elm.hasChildNodes()) {
        elm.children[0].remove();
      }

      const codeElm = dom.add(elm, 'code');
      // Needs to be textContent since innerText produces BR:s
      codeElm.textContent = dom.decode(code);
    }
  }
}

// Used to toggle the active state of the Code Sample button in the toolbar
const toggleActiveState = (editor: Editor) => api => {
  return Actions.toggleState(editor, () => {
    api.setActive(isCodeSampleSelection(editor));
  });
};

export {
  isCodeSample,
  trimArgument,
  handleSetContent,
  handlePreProcess,
  getCurrentLanguage,
  toggleActiveState
};
