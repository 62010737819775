import type { AppContextInterface } from '@aurora/shared-client/components/context/AppContext/AppContext';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import type { PageAndEmptyParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type { ParsedUrlQuery } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import React, { useContext } from 'react';

/**
 * Returns a function that takes react node as argument to render within the page editor link.
 */
export function usePageEditorLink() {
  const { contextMessage, contextUser, contextNode }: AppContextInterface = useContext(AppContext);
  const { pageId, templateId } = useContext(PageContext);
  const { Link, router } = useEndUserRoutes();
  const searchTerm = router.getUnwrappedQueryParam(EndUserQueryParams.SEARCH_KEY);

  const queryParams: ParsedUrlQuery = {
    [EndUserQueryParams.PAGE_ID]: pageId
  };
  if (templateId) {
    queryParams[EndUserQueryParams.TEMPLATE_ID] = templateId;
  }
  if (contextUser) {
    queryParams[EndUserQueryParams.USER_ID] = contextUser.id;
  }

  if (contextNode && contextNode.nodeType !== 'community') {
    queryParams[EndUserQueryParams.NODE_ID] = contextNode.id;
  }

  if (contextMessage) {
    queryParams[EndUserQueryParams.MESSAGE_ID] = contextMessage.id;
  }

  if (searchTerm) {
    queryParams[EndUserQueryParams.SEARCH_KEY] = searchTerm;
  }

  return {
    link: (content: React.ReactNode) => {
      return (
        <Link<PageAndEmptyParams>
          route={EndUserPages.PageEditorPage}
          passHref
          query={queryParams}
          legacyBehavior={true}
        >
          {content}
        </Link>
      );
    }
  };
}
