import type { FormatMessage } from '@aurora/shared-types/texts';
import { getLog } from '@aurora/shared-utils/log';
import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';
import type { AstNode, Editor, EditorEvent } from 'tinymce';
import type SearchableSelectFieldEntry from '../../components/form/SearchableSelectFieldEntry/SearchableSelectFieldEntry';
import { entriesFromJson } from '../../components/form/SearchableSelectFieldEntry/SearchableSelectFieldEntry';
import {
  getCurrentLanguage,
  handlePreProcess,
  handleSetContent,
  isCodeSample,
  toggleActiveState
} from './EditorCodeSampleHelperInternal';
import {
  CustomEditorButton,
  focusEditorNextTick,
  getHtmlElement,
  getNodeToReplace,
  insertContentWithParagraph
} from './EditorHelper';
import languages from './language-data.json';
import type { OnAction } from './TinyMceInternalHelper';

const log = getLog(module);

export interface CodeSampleInfo {
  /**
   * The language used for the code sample.
   */
  lang: SearchableSelectFieldEntry;
  /**
   * The code entered for the code sample.
   */
  code: string;
}

const LI_CODE_ELEMENT = 'li-code';
export const CODE_DATA_LANG = 'data-lia-code-lang';
export const DATA_CODE_VALUE = 'data-lia-code-value';

const codeSampleLanguages: SearchableSelectFieldEntry[] = entriesFromJson(languages);

/**
 *  Create the HTML Template for code sample.
 *
 * @param lang the language selected for code sample.
 * @param content the code added.
 */
function createHtmlTemplate(lang = '', content = ''): string {
  return `<pre class="lia-code-sample line-numbers language-${lang}" ${CODE_DATA_LANG}="${lang}" ${DATA_CODE_VALUE}="${content}" contenteditable="false"><code>${content}</code></pre>`;
}

/**
 *  Get the content from the node which can be `li-code` or `pre` node.
 *
 *  @param node the TinyMce node.
 *  @param editor the TinyMce editor.
 */
function getContentFromNode(node: AstNode, editor: Editor): string {
  const element = getHtmlElement(editor, node);
  const { liaCodeValue } = element.dataset;
  return liaCodeValue ?? element.textContent;
}

/**
 * Load Prism and run "highlightAll"
 */
async function prismHighlightAll() {
  return import('./PrismHelper')
    .then(() => {
      const prism = global.Prism;
      prism.highlightAll();
      return prism;
    })
    .catch(error => {
      log.warn(error, 'Unable to load PrismHelper. Code highlighting may be affected.');
    });
}

/**
 *  Register a node filter that converts the `li-code` markup to the HTML inserted in the editor.
 *
 * @param editor the TinyMce editor.
 */
function registerXmlToHtmlConverter(editor: Editor): void {
  editor.parser.addNodeFilter(LI_CODE_ELEMENT, nodes => {
    nodes.forEach(node => {
      const content = getContentFromNode(node, editor);
      const template = createHtmlTemplate(node.attr('lang'), editor.dom.encode(content));
      const preNode = getNodeToReplace(template, editor);
      node.replace(preNode);
    });
    prismHighlightAll();
  });
}

/**
 *  Register an attribute filter that converts the code sample HTML markup to `li-code` XML that is used to store
 *  the content.
 *
 * @param editor the TinyMce editor.
 */
function registerHtmlToXmlConverter(editor: Editor): void {
  editor.serializer.addAttributeFilter(CODE_DATA_LANG, nodes => {
    nodes.forEach(node => {
      const content = getContentFromNode(node, editor);
      const liCodeTemplate = `<pre><li-code>${editor.dom.encode(content)}</li-code></pre>`;
      const liCodeNode = getNodeToReplace(liCodeTemplate, editor);
      liCodeNode.attr('lang', node.attr(CODE_DATA_LANG));
      node.replace(liCodeNode);
    });
    prismHighlightAll();
  });
}

/**
 *  Register the Code Sample Context Toolbar which is presented when a code sample is selected.
 *
 * @param editor the TinyMce editor.
 */
function registerCodeSampleContextToolbar(editor: Editor) {
  editor.ui.registry.addContextToolbar(CustomEditorButton.CODE_SAMPLE, {
    predicate(node) {
      return node.nodeName.toLowerCase() === 'pre' && node.className.includes('language-');
    },
    items: 'liaCodeSample',
    position: 'node',
    scope: 'node'
  });
}

/**
 *  Register the Code Sample button with TinyMce.
 *
 * @param editor the TinyMce editor.
 * @param formatMessage localizes messages.
 * @param onAction a callback when the code sample button is interacted with.
 */
function registerButton(editor: Editor, formatMessage: FormatMessage, onAction: OnAction) {
  editor.ui.registry.addToggleButton(CustomEditorButton.CODE_SAMPLE, {
    icon: 'code-sample',
    tooltip: formatMessage('codeSampleTooltip'),
    onAction,
    onSetup: toggleActiveState(editor)
  });
}

/**
 *  Set the Code Sample markup in the editor.
 *
 * @param node the currently selected node in the editor.
 * @param editor the TinyMce editor.
 * @param data the CodeSampleInfo containing language and content.
 */
function setCodeSampleInEditor(node: Element, editor: Editor, data: CodeSampleInfo) {
  editor.undoManager.transact(() => {
    const lang = data.lang || { value: '', label: '' };
    let { code } = data;
    let codeSampleNode = !isCodeSample(node) ? null : node;
    code = editor.dom.encode(code);

    if (codeSampleNode === null) {
      insertContentWithParagraph(
        editor,
        `<pre id="__new" class="language-${lang.value} line-numbers" ${DATA_CODE_VALUE}="${code}"><code>${code}</code></pre>`
      );
      const newElement = editor.dom.select('#__new');
      editor.dom.setAttrib(newElement[0], 'id', '');
      codeSampleNode = newElement[0] as Element;
    } else {
      editor.dom.setAttrib(codeSampleNode, 'class', `language-${lang.value} line-numbers`);
      editor.dom.setAttrib(codeSampleNode, DATA_CODE_VALUE, code);
      codeSampleNode.innerHTML = `<code>${code}</code>`;
    }
    // eslint-disable-next-line promise/catch-or-return,promise/always-return
    prismHighlightAll().then(() => {
      codeSampleNode.setAttribute(CODE_DATA_LANG, lang.value);
      codeSampleNode.setAttribute(DATA_CODE_VALUE, code);
      codeSampleNode.classList.add('lia-code-sample');
      editor.selection.select(codeSampleNode);
      editor.selection.setCursorLocation(codeSampleNode.nextElementSibling, 0);
      focusEditorNextTick(editor);
    });
  });
}

/**
 * Get the language as LanguageData object given the language code(value)
 *
 * @param lang Language's code as defined by Prism JS
 */
function getLang(lang: string): SearchableSelectFieldEntry {
  return codeSampleLanguages.find(languageObject => languageObject.value === lang);
}

/**
 *  Get the CodeSampleInfo object from the selected code sample.
 *
 * @param node the currently selected element in the editor.
 */
function getCodeSampleInfoFromEditor(node: Element): CodeSampleInfo {
  const selectedNode = !isCodeSample(node) ? null : node;
  if (selectedNode === null) {
    return { lang: { value: '', label: '' }, code: '' };
  } else {
    const lang = getLang(getCurrentLanguage(selectedNode)) || { value: '', label: '' };
    return { lang, code: selectedNode.textContent };
  }
}

/**
 *  Register double click for the selected code sample in the editor.
 *
 * @param editor the TinyMce editor.
 * @param onAction the callback when a code sample is double-clicked.
 */
function registerDblClick(editor: Editor, onAction: OnAction) {
  editor.on('dblclick', (event: EditorEvent<Event>) => {
    if (isCodeSample(event.target)) {
      onAction();
    }
  });
}

/**
 *  Register markdown support for fenced code blocks to generate 'pre' blocks
 *
 * @param editor the TinyMce editor.
 */
function registerCommand(editor: Editor): void {
  editor.addCommand('liaCodeSample', () => {
    editor.undoManager.transact(() => {
      const currentNode = editor.selection.getNode();
      let nodeString = editor.serializer.serialize(currentNode);
      const nodeContent = editor.parser.parse(nodeString)?.firstChild.children();
      const nodeContentLength = nodeContent.length;
      const lastNode = nodeContent[nodeContentLength - 1];
      // Minimum one line-break and ending ```
      if (nodeContentLength >= 2 && lastNode?.value.endsWith('```')) {
        // Strip <p> and ``` from start and end
        nodeString = nodeString.slice(3, nodeString.lastIndexOf('```'));
        let language = '';
        if (!nodeString.startsWith('<br>')) {
          language = nodeString.slice(0, nodeString.indexOf('<br>'));
          nodeString = nodeString.slice(language.length);
        }
        // Need to select a previous node so 'pre' can be inserted after it
        let previousElement = currentNode.previousElementSibling;
        const placeholderParaId = 'pre-next';
        if (previousElement) {
          if (previousElement.nodeName === 'PRE') {
            previousElement.after(editor.dom.create('p', { id: placeholderParaId }, '<br>'));
            previousElement = previousElement.parentElement.querySelector(`#${placeholderParaId}`);
          }
        } else {
          currentNode.before(editor.dom.create('p', { id: placeholderParaId }, '<br>'));
          previousElement = currentNode.parentElement.querySelector(`#${placeholderParaId}`);
        }
        editor.selection.select(previousElement, true);
        editor.selection.collapse(false);
        nodeString = editor.dom.decode(nodeString);
        // Create code in format parsable by prismjs
        const data: CodeSampleInfo = {
          lang: { label: '', value: language },
          code: nodeString.replaceAll('<br>', '\n')
        };
        setCodeSampleInEditor(previousElement, editor, data);
        if (previousElement?.id === placeholderParaId) {
          // Cleanup placeholder
          previousElement.remove();
        }
        editor.selection.select(currentNode);
        // This event is required to clean the empty para the tinymce generates post completion of this command
        editor.once('NodeChange', ({ element }) => {
          element.remove();
        });
        // Clear the current node
        editor.dom.setOuterHTML(currentNode, '<p><br data-mce-bogus="1"></p>');
      }
    });
  });
}

/**
 *  Register all utilities related to Code Sample in TinyMce Editor such as register button, context toolbar and
 *  registering converters for XML to HTML conversion and vice-versa.
 *
 * @param editor the TinyMce editor.
 * @param cx the cx/CSS-classes for this HTML are currently defined in `RichTextEditorField.module.pcss`.
 * @param formatMessage localizes messages.
 * @param onAction the callback when code sample is interacted with.
 * @param currentCodeSampleSelected callback to update the current code sample selected
 */
export default function registerCodeSample(
  editor: Editor,
  cx: ClassNamesFnWrapper,
  formatMessage: FormatMessage,
  onAction: OnAction,
  currentCodeSampleSelected: (currentSelection: HTMLElement) => void
) {
  registerButton(editor, formatMessage, onAction);
  registerCodeSampleContextToolbar(editor);
  registerDblClick(editor, onAction);
  registerCommand(editor);

  // This is required in tinymce version 6 to wrap li-code around pre tag to preserve whitespace
  editor.on('BeforeSetContent', event => {
    const { content } = event;
    if (content && content.includes('LI-CODE')) {
      const divElement = document.createElement('div');
      divElement.innerHTML = content;

      const codeElements = divElement.querySelectorAll('li-code');
      codeElements.forEach(codeElement => {
        const preElement = document.createElement('pre');

        preElement.append(codeElement.cloneNode(true));
        codeElement.replaceWith(preElement);
      });
      event.content = divElement.innerHTML;
    }
  });

  editor.on('SetContent', () => {
    handleSetContent(editor);
  });

  editor.on('PreProcess', () => {
    handlePreProcess(editor);
  });

  // These converters in tinymce 6 are supposed to be run after BeforeSetContent.
  editor.on('PostProcess', () => {
    registerXmlToHtmlConverter(editor);
    registerHtmlToXmlConverter(editor);
  });

  const eventCallback = () => {
    const [element] = editor.selection.getSelectedBlocks();
    currentCodeSampleSelected(element as HTMLElement);
  };
  editor.on('NodeChange', eventCallback);
}

export {
  setCodeSampleInEditor,
  getCodeSampleInfoFromEditor,
  createHtmlTemplate,
  prismHighlightAll
};
