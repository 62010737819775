/**
 * A language in SearchableSelectField
 */
export default class SearchableSelectFieldEntry {
  /**
   * Value/Code of the language as defined by PrismJS
   */
  label: string;

  /**
   * Display text of the language in the SearchableSelectField
   */
  value: string;

  constructor(key: string, value: string) {
    this.value = key;
    this.label = value;
  }
}

/**
 * Helper function to transform json data into an array of SearchableSelectFieldEntry
 *
 * @param jsonData of format {"<value>": "<label>"}
 */
function entriesFromJson(jsonData: Record<string, string>): SearchableSelectFieldEntry[] {
  return Object.keys(jsonData).map(key => new SearchableSelectFieldEntry(key, jsonData[key]));
}

export { entriesFromJson };
